import { Box, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import img from '../../assets/New.png';
const paragraph = {
  fontWeight: 500,
  fontSize: '29px',
  lineHeight: '47px',
  color: '#445E77',
  margin: '0 auto',
  marginTop: '16px',
  '@media (max-width: 900px)': {
    fontSize: '20px',
    marginTop: '0px',
  },
};
const titlt = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '40px',
  lineHeight: '47px',
  color: '#445E77',
  margin: '0 auto',
  marginTop: '30px',
  '@media (max-width: 900px)': {
    fontSize: '30px',
    marginTop: '20px',
  },
};
const text = {
  fontWeight: 400,
  fontSize: '16px',
};

const PaymentMethods = () => {
  const { auth } = useSelector((state) => state.auth);

  return (
    <Box
      sx={
        auth
          ? {
              paddingTop: '100px',
              paddingBottom: '20px',
              // background: '#CFCFCF',
              // background:
              //   "linear-gradient(89.86deg, rgba(73, 148, 43, 0.2) 18.5%, rgba(68, 94, 119, 0.2) 74.84%)",
              // width: "100%",
              '@media (max-width: 900px)': {
                paddingTop: '80px',
              },
            }
          : {
              paddingTop: '10px',
              paddingBottom: '20px',
              background: '#CFCFCF',
              // background:
              //   "linear-gradient(89.86deg, rgba(73, 148, 43, 0.2) 18.5%, rgba(68, 94, 119, 0.2) 74.84%)",
              width: '100%',
            }
      }>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '1000px',
            margin: '0 auto',
          }}>
          <Typography variant="h4" sx={paragraph}>
            Кто и за что платит?
          </Typography>
          <Typography sx={{ mt: 1, ...text }} component="div" variant="body2">
            Небольшие правила сервиса по оплате за отклики.
          </Typography>
          <Typography sx={{ mt: 1, ...text }} component="span" variant="body2">
            Пока заказчик не выбрал нужного специалиста, ни кто не за что не платит.
          </Typography>
          <Typography sx={{ mt: 1, ...text }} component="span" variant="body2">
            После того как заказчик, оставил задание на платформе «Лига услуг», специалисты видят
            данное задание по своим категориям, что указали в анкете. Если данное задание
            специалисту интересно, удобно по времени, устраивает стоимость заказа, то специалист
            откликается на данное задание, с возможностью предложить своё время для выполнения
            заказа, свою стоимость. Нажимает кнопку ОТКЛИКНУТЬСЯ, заполняет необходимые данные по
            алгоритму, и отправляет предложение заказчику. В этот момент с баланса специалиста
            снимается определённая сумма за данный отклик. Каждый отклик, стоит определённую
            стоимость. Сумма за отклик снимается с каждого специалиста, кто отправил предложение на
            задание. Далее заказчик изучает предложения от специалистов. По желанию уточняет в
            личной переписке, на площадке « Лига Услуг» с каждым специалистом детали, смотрит
            рейтинг и отзывы, количество выполненных заданий специалиста. И уже определившись, с кем
            будет работать, выбирает одного из нескольких ответивших специалистов. Как только
            заказчик выбрал специалиста, это значит, что произошла сделка, и баланс в личном
            кабинете у данного специалиста остаётся неизменным.Всем остальным специалистам, которые
            откликнулись на задание, приходит уведомление, что заказчик выбрал нужного ему
            специалиста, и снятая сумма за ответ возвращается остальным специалистам на их баланс.
          </Typography>
          <Typography sx={{ mt: 1, ...text }} component="span" variant="body2">
            То есть если кратко – на этапе подбора заказчиком специалиста, за отклики платят все
            откликнувшиеся специалисты. Оплата происходит автоматически, с баланса в личном
            кабинете. При этом баланс должен быть положительным,что бы хватало на стоимость отклика,
            в ином случае не получится откликнуться на заказ. После того как специалист выбрал
            специалиста, всем остальным приходит уведомление о том, что заказчик выбрал нужного
            специалиста, и на баланс возвращается сумма за отклик. А специалисту которого выбрали,
            снятая сумма за отклик не возвращается.
          </Typography>
          <Typography variant="h4" sx={paragraph}>
            Регистрация специалистов и заказчиков
          </Typography>

          <Typography sx={{ mt: 1, ...text }} component="span" variant="body2">
            Лига Услуг предлагает уникальный алгоритм регистрации. В отличии от других площадок, где
            нужно создавать отдельно страницы для исполнителей и для заказчиков, тратить на это
            много времени, заполнять кучу информации. Ещё и не всё сразу у всех получается. А может
            и вообще не получится провести регистрацию. Мы решили данную задачу и всё упростили,
            так, что бы каждый мог произвести регистрацию. И самое главное один раз.
          </Typography>
          <Typography sx={{ mt: 1, ...text }} component="span" variant="body2">
            То есть, при первой регистрации, вы создаёте себе один аккаунт в Лига услуг. После того
            как вы заполнили самые первые данные, сохранили логин и пароль, вы попадаете на страницу
            заказчика.
          </Typography>

          <Box sx={{ mt: 1, ...text }}>
            <Box
              sx={{
                backgroundImage: `url(${img})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                width: '100%',
                paddingTop: 'calc((16/21)*100%/2)',
              }}
            />
          </Box>
          <Typography component="span" variant="body2" sx={{ mt: 1, ...text }}>
            Далее в анкете заполняете необходимые данные как заказчик, и действуете по алгоритму
            сервиса Лига Услуг. Не переходя, и не создавая новых аккаунтов, вы можете переключаться
            между аккаунтами заказчика и исполнителя.
          </Typography>
          <Typography component="span" sx={{ mt: 1, ...text }} variant="body2">
            Что это даёт? В чём преимущество? Один логин и пароль для двух аккаунтов, как для
            исполнителя, так и для заказчика. Один раз регистрируетесь, и пользуетесь двумя
            аккаунтами.
          </Typography>
          <Typography component="span" variant="body2" sx={{ mt: 1, ...text }}>
            Если вы просто решили создать аккаунт, и зарегистрироваться в Лига Услуг, как заказчик,
            то вам не обязательно переходить в личный кабинет исполнителя. Для того, что бы находить
            специалистов, и оставлять задания, достаточно тех данных, что вы заполняете в личном
            кабинете заказчика.
          </Typography>
          <Typography component="span" variant="body2" sx={{ mt: 1, ...text }}>
            Если вы специалист, то после того как создали аккаунт, достаточно переключиться в
            кабинет исполнителя. После переключения аккаунта на исполнителя, нужно будет заполнить
            данные как исполнителя, но без дополнительной регистрации.
          </Typography>
          <Typography component="span" variant="body2" sx={{ mt: 1, pb: 3, ...text }}>
            И в таком случае, вы получаете два аккаунта ,с одной регистрацией. Так же данная функция
            позволяет, исполнителям и самим оставлять задания, достаточно переключиться с кабинета
            исполнителя, в кабинет заказчика.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
export default PaymentMethods;
