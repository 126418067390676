import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {useStyles} from "./ModalNewTask";
import HeaderModal from "./blocks/HeaderModal";
import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 3,
};
const ModalTermsOfUse = ({showTermsOfUse, setShowTermsOfUse}) => {
    const classes = useStyles();
    return (
        <div>
            <Modal
                open={showTermsOfUse}
                onClose={() => setShowTermsOfUse(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{border: 'none'}}
            >
                <Box style={{maxHeight: '60%', overflow: 'auto', paddingTop: 0}} sx={(theme) => {
                    return {...style}
                }}>
                    <Box className={classes.root}>
                        <HeaderModal title={'Условия использования сервиса «Лига Услуг»'}
                                     close={() => setShowTermsOfUse(false)}
                        />
                    </Box>
                    <Box>
                        <Typography>
                            1. Общие условия
                        </Typography>
                        <Typography>
                            1.1. В целях настоящего документа нижеприведенные термины используются в следующем
                            значении:
                        </Typography>
                        <Typography>
                            Пользователь – пользователь сети «Интернет», использующий функциональные возможности
                            Сервиса и его отдельных функций;
                        </Typography>
                        <Typography>
                            Сервис услуг – сервис Лига услуг, доступный по адресу ( вставить адрес ) и на иных ресурсах,
                            Условия – настоящие Условия использования сервиса Лига услуг опубликованные по адресу
                            ( вставить адрес )
                        </Typography>
                        <Typography>
                            Исполнитель – пользователь, размещающий в сервисе объявление о своих услугах, либо
                            осуществляющий отклики на заказы;
                        </Typography>
                        <Typography>
                            Услуга – услуга, работа, купля-продажа и доставка товара, в отношении которых
                            пользователь
                            разместил объявление или заказ, либо осуществил отклик.
                        </Typography>
                        <Typography>
                            Партнер – лицо, вступившее с Лига Услуг в договорные отношения, касающиеся использования
                            сервиса в коммерческой деятельности партнера с целью привлечения клиентов, а также в иных
                            целях.
                        </Typography>
                        <Typography>
                            Заявка – заявка клиента ( пользователя ) о необходимости оказать услугу на указанных в нем
                            условиях. Заявка может быть опубликована на странице Лига услуг и доступна неопределенному
                            кругу лиц кто указал данное направление услуг в своих настройках, либо может быть направлен
                            посредством функциональных возможностей сервиса в отношении конкретного предложения
                            оказания услуг.
                        </Typography>
                        <Typography>
                            Клиент – пользователь сервиса, размещающий в сервисе заявки на оказание услуг, или поиск
                            специалиста в определённом направлении;
                        </Typography>
                        <Typography>
                            Отклик – сообщение клиенту от специалиста с информацией о сроках, стоимости исполнения
                            данного задания
                        </Typography>
                        <Typography>
                            Аккаунт – учетная запись пользователя Лига услуг;
                        </Typography>
                        <Typography>
                            Публичная страница – страница исполнителя, доступная в Лига услуг для неограниченного круга
                            лиц, содержащая информацию об исполнителе, в том числе его контактные данные, информацию
                            об оказываемых им услугах, опыте работы, специальных отметках сервиса, оценках, рейтинге на
                            сервисе, личное фото, город проживания, и т.д;
                        </Typography>
                        <Typography>
                            Контент – любые текстовые, фото, видео-материалы, загружаемые пользователем в сервис или
                            автоматически переносимые им в сервис.
                        </Typography>
                        <Typography>
                            2. Лига услуг предлагает любому пользователю использовать данную интернет площадку, на
                            настоящих Условиях .
                        </Typography>
                        <Typography>
                            3. Использование Данной интернет площадки регулируется настоящими условиями.
                        </Typography>
                        <Typography>
                            4. Начиная использовать Лига услуг и его отдельные функции, пользователь считается принявшим
                            настоящие условия, в полном объеме, без всяких оговорок и исключений. В случае несогласия
                            пользователя с какими-либо из положений указанных документов, пользователь в праве не
                            использовать Лига услуг.
                        </Typography>
                        <Typography>
                            5. Настоящие условия, могут быть изменены без уведомления. Новые условия вступают в силу с
                            момента их размещения в сети интернет. Продолжение пользования Лига услуг после изменения
                            условий считается согласием с их новыми изменениями.
                        </Typography>
                        <Typography>
                            6. Используя Лига услуг, пользователь дает свое согласие на получение сообщений
                            информационного или рекламного характера.
                        </Typography>
                        <Typography>
                            7. Лига услуг предоставляет клиентам, возможность размещения, поиска и просмотра объявлений
                            и поиска исполнителей, а исполнителям откликов на заказы, и также другие возможности, для
                            всех пользователей Лига услуг.
                        </Typography>
                        <Typography>
                            8. Функционал сервиса может быть доступен пользователю после регистрации на сервисе Лига
                            Услуг, согласно условиям.
                        </Typography>
                        <Typography>
                            9. Пользователь, после авторизации, имеет право создать аккаунта в Лига услуг, после чего
                            может
                            создавать объявления, размещать задания на услугу, осуществлять отклики на заявки, добавлять
                            информацию в профиль, редактировать свой профиль.
                        </Typography>
                        <Typography>
                            Нельзя создавать несколько аккаунтов. В случае выявления у пользователя дополнительного
                            аккаунта, данный пользователь может быть заблокирован навсегда в сервисе Лига Услуг, без
                            предупреждения, или аннулирован.
                        </Typography>
                        <Typography>
                            10. Лига услуг предоставляет зарегистрированному пользователю возможность писать сообщения
                            другому пользователю данной интернет площадки, возможность проводить обсуждения
                            исполнителей между исполнителями, и так же клиентов между клиентами.
                        </Typography>
                        <Typography>
                            В целях осуществления контроля качества, в целях проверки факта и условий оказания услуг
                            клиенту исполнителем, в рамках выполнения требований законодательства, Лига Услуг хранит
                            историю взаимодействия между клиентом и исполнителем.
                        </Typography>
                        <Typography>
                            11. Клиент имеет возможность публиковать отзывы, и ставить баллы исполнителю. Отзыв должен
                            выражать оценку клиента относительно оказанных ему услуг или любого взаимодействия с
                            исполнителем, отзыв и оценка должны содержать достоверно изложенные фактические
                            обстоятельства опыта обращения к исполнителю, без каких либо оскорблений. Отзыв клиент,
                            может опубликовать, только от своего имен.
                        </Typography>
                        <Typography>
                            12. Лига услуг может осуществлять проверку благонадежности исполнителя основываясь на
                            данных, предоставленных исполнителем при создании аккаунта в Лига услуг. Проверка может
                            подразумевать использование открытых источников и баз данных, а также привлечение третьих
                            лиц.
                        </Typography>
                        <Typography>
                            По результатам проведенной проверки благонадежности исполнителя, а также в случае отказа
                            исполнителя от предоставления документов, Лига услуг может отказать исполнителю в
                            предоставлении доступа к сайту Лига услуг, без предоставления пояснений причин отказа.
                        </Typography>
                        <Typography>
                            13. Пополняя личный счет исполнителя в Лига услуг, поступившая денежная сумма, направляется
                            на личный счет исполнителя в Лига услуг и может быть использована для возможности выполнять
                            отклики на задания от клиентов, или возвращена по запросу исполнителя, равной фактической
                            суммы на момент запроса на возврат денежных средств.
                        </Typography>
                        <Typography>
                            Лига услуг имеет возможность возможность оценивать активность исполнителя, исходя из
                            установленных сайтом критериев, таких как: частота откликов, подтверждение договоренности с
                            заказчиком об услуге, подтверждение и закрытие выполненных заказов, скорость ответа
                            клиентам, рейтинг исполнителя, определяемый на основании отзывов и оценок от клиентов, и
                            другое.
                        </Typography>
                        <Typography>
                            Оценки и рейтинг исполнителя доступен в личном кабинете исполнителя. Его видят все
                            пользователи сайта.
                        </Typography>
                        <Typography>
                            Так же у администраторов сайта будет возможность временно ставить в бан на некоторое время
                            исполнителей.
                        </Typography>
                        <Typography>
                            14. Лига услуг предоставляет исполнителям откликаться на заявки, приходящие от клиентов.
                            Когда исполнитель выполнил отклик и предложил условия выполнения заказа, клиенту приходит
                            предложение вступить в переговоры по поводу оказания услуги на таких то условиях, за такую
                            то
                            стоимость. Заказчик, изучив все предложения, сам принимает решение какого специалиста
                            выбрать в роли исполнителя.
                        </Typography>
                        <Typography>
                            Исполнителю запрещается при выполнении откликов, злоупотреблять функциональными
                            возможностями сервиса Лига услуг, использование каких-либо программ, ботов, позволяющих
                            размещать отклики в автоматическом режиме.
                        </Typography>
                        <Typography>
                            Аккаунт исполнителя может содержать информацию о количестве выполненных заказов с
                            использованием сервиса Лига услуг.
                        </Typography>
                        <Typography>
                            15. Размещая карточку специалиста в сервисе Лига услуг, исполнитель соглашается с тем, что
                            предоставленные им сведения, включая контактные данные, личное фото, контент будут
                            доступны неограниченному кругу лиц на территории всего мира.
                        </Typography>
                        <Typography>
                            Лига услуг не может гарантировать, что данные сведения могут быть использованы третьими
                            лицами, а также не несет ответственности за действия третьих лиц по копированию и обработке
                            предоставленной пользователем информации в целях, не связанных с использованием Сервиса.
                            Размещая контактные данные, пользователь соглашается на запись сервисом Лига услуг
                            разговора и номера телефона, с которого совершен звонок. Записи звонков могут быть
                            использованы в целях контроля качества работы сервиса Лига услуг, рассмотрения жалоб
                            пользователей и контроля качества оказания услуг специалистами.
                        </Typography>
                        <Typography>
                            16. Исполнитель создавая карточку специалиста в сервисе Лига услуг, обязуется осуществлять
                            отклики только в отношении услуг, оказываемых им лично, нельзя выдавать себя за другое лицо,
                            перепоручать задания от клиентов, перепродавать, производить обмен заказов, полученных или
                            взятых в сервисе Лига услуг, как на возмездной, так и на безвозмездной основе.
                        </Typography>
                        <Typography>
                            17.Пользователь самостоятельно и всецело несёт все риски и ответственность за соответствие
                            законодательству, содержание, полноту, корректность и достоверность размещенной им
                            информации в сервисе Лига услуг.
                        </Typography>
                        <Typography>
                            Запрещено использование сайта Лига услуг и его функций с целью загрузки, размещения,
                            передачи, распространения не разрешенной специальным образом рекламной информации,
                            различной рассылки, спама, материалов, содержащих вирусы или другие вредоносные
                            компьютерные коды, файлы или программы, использовать сайт исключительно для
                            перенаправления пользователей на страницы других сайтов.
                        </Typography>
                        <Typography>
                            Лига услуг оставляет за собой право без предупреждения и по своему усмотрению удалить любое
                            объявление, информацию, опубликованную на сайте, или ограничить доступ пользователя к сайту
                            в том числе, в случае, если действия пользователя, размещение контента или иной информации
                            противоречит законодательству Российской Федерации, настоящим условиям, а также
                            требованиям сайта к публикуемым материалам.
                        </Typography>
                        <Typography>
                            18. Лига услуг предоставляет всем пользователям бесплатную возможность размещения заказов
                            на поиск специалиста в каком либо направлении.
                        </Typography>
                        <Typography>
                            Клиент настоящим уведомлен и соглашается, что в случае оформления заявки на поиск
                            специалиста через платформу Лига услуг, вся предоставленная им информация может быть
                            передана привлекаемым исполнителям, назначенным исполнителем для оказания услуги, в целях
                            обратной связи по вопросам оказания услуг и фактического выполнения заявки.
                        </Typography>
                        <Typography>
                            Услуги клиенту оказывает исполнитель с сайта Лига услуг, но при этом Лига услуг не является
                            участником отношений по оказанию исполнителем услуг клиенту, и не может нести
                            ответственность за качество, своевременность, полноту оказанных услуг, а также за действия
                            исполнителя.
                        </Typography>
                        <Typography>
                            Лига услуг рекомендует всем клиентам и кто ищет специалистов через Лига услуг, после выбора
                            специалиста, не вносить не каких денежных авансов, а предварительно детально всё обсудить,
                            договориться, и заключить письменный договор, между специалистом и клиентом.
                            Все наличные и безналичные расчеты между клиентом и исполнителем вне сайта
                            осуществляются сторонами напрямую без участия Лига услуг.
                        </Typography>
                        <Typography>
                            19. Указанная информация об услугах, условиях и стоимости их оказания, указанная на сайте
                            Лига
                            услуг, носит справочный характер. Конечную стоимость услуги необходимо уточнять у
                            выбранного специалиста.
                        </Typography>
                        <Typography>
                            Все претензии, вопросы, недовольства, связанные с оказанными услугами исполнителями,
                            должны быть направлены заказчиком в адрес самого исполнителя, по любым доступным
                            контактным данным указанными в карточке исполнителя.
                        </Typography>
                        <Typography>
                            Лига услуг не может гарантировать 100% соответствия сайта целям и ожиданиям пользователя,
                            идеальную и безошибочную работу сайта. Любой зарегистрированный пользователь должен
                            самостоятельно оценивать все риски, связанные с использованием информации, включая оценку
                            надежности, полноты или полезности, а также их соответствие законодательству.
                        </Typography>
                        <Typography>
                            Лига услуг не является участником правоотношений, устанавливаемых между клиентом и
                            исполнителем по поводу оказания услуг, не выполняет в ней функции посредничества, не
                            отвечает за исполнение сторонами своих обязанностей, и не оказывает консультаций по
                            правовым, финансовым и организационным вопросам.
                        </Typography>
                        <Typography>
                            Клиент и исполнитель обязуются самостоятельно улаживать все споры, возникающие в связи с
                            заключением и исполнением сделки по поводу предлагаемых и запрашиваемых услуг, в том
                            числе направлять претензии напрямую в адрес участника такой сделки.
                        </Typography>
                        <Typography>
                            20. Лига услуг оставляет за собой право на свое усмотрение ограничить доступ исполнителя к
                            сайту с использованием его учетной записи или полностью заблокировать аккаунт, при выявлении
                            подозрительной активности на сайте, а также применить к исполнителю иные меры с целью
                            соблюдения требований настоящих условий.
                        </Typography>
                        <Typography>
                            Лига услуг оставляет за собой право ограничить доступ клиента к сайту или заблокировать
                            профиль клиента на сайте в случае выявления подозрительных действий на сайте.
                        </Typography>
                        <Typography>
                            Любая информация, используемая на сайте Лига услуг, предназначена исключительно для
                            личного некоммерческого использования. Любое копирование информации с сайта,
                            воспроизведение, переработка, распространение, любое использование в средствах массовой
                            информации или в коммерческих целях без предварительного письменного разрешения Лига
                            услуг запрещается.
                        </Typography>
                        <Typography>
                            Любой пользователь соглашается с тем, что не будет использовать информацию и контент,
                            размещенные на сайте Лига услуг, без разрешения Лига услуг, в случаях, когда такое
                            использование не связано с целями совершения и исполнения сделок. Запрещается использовать
                            контактные данные исполнителя или клиента без согласия исполнителя или клиента.
                            По вопросам и претензиям, связанным с использованием или невозможностью использования
                            сайта Лига услуг, а также с возможным нарушением законодательства или прав третьих лиц на
                            сайте Лига услуг, пользователь может направить свое обращение через форму обратной связи по
                            адресу liga124@mail.ru
                        </Typography>
                        <Typography style={{textAlign: 'right'}}>
                            Дата публикации: 6 июня 2022 г.
                        </Typography>
                        <Box style={{display: 'flex', justifyContent: 'center'}}>
                            <Button color='success' variant="contained" onClick={() => setShowTermsOfUse(false)}>Принимаю</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalTermsOfUse;
