import Container from '@mui/material/Container';
import React from 'react';
import { Avatar, Box, TextField, Typography, useMediaQuery } from '@mui/material';

function AboutLiga() {
  const lg = useMediaQuery('(max-width:1440px)');
  return (
    <Container maxWidth={lg ? 'lg' : 'xl'}>
      <Box>
        <Typography
          sx={{ color: '#FF6B00', textAlign: 'center', fontSize: '24px', fontWeight: '600', paddingBottom:"52px" }}>
          О сервисе “Лига услуг”
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent:"center", gap:"50px 20px" , paddingBottom:"300px"}}>
          <Box sx={{ display: 'flex',  width: '600px', gap:"50px" , padding:"50px 20px 50px 50px", border:"1px solid black"}}>
            <Avatar sx={{width:"100px", height:"100px"}}/>
            <Box>
              <Typography sx={{color:"#000", fontSize:"24px"}}>Методы платежей </Typography>
              <Typography>
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который.
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex',  width: '600px', gap:"50px" , padding:"50px 20px 50px 50px", border:"1px solid black" }}>
            <Avatar sx={{width:"100px", height:"100px"}}/>
            <Box>
              <Typography sx={{color:"#000", fontSize:"24px"}}>Методы платежей </Typography>
              <Typography>
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который.
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex',  width: '600px' , gap:"50px" , padding:"50px 20px 50px 50px", border:"1px solid black"}}>
            <Avatar sx={{width:"100px", height:"100px"}}/>
            <Box>
              <Typography sx={{color:"#000", fontSize:"24px"}}>Методы платежей </Typography>
              <Typography>
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который.
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex',  width: '600px' , gap:"50px" , padding:"50px 20px 50px 50px", border:"1px solid black"}}>
            <Avatar sx={{width:"100px", height:"100px"}}/>
            <Box>
              <Typography sx={{color:"#000", fontSize:"24px"}}>Методы платежей </Typography>
              <Typography>
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который.
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
                Единый центр услуг - это удобный, динамично развивающийся Единый Сервис который
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default AboutLiga;
