import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import AboutLigaImg from '../../../../assets/image/AboutLigaImg.png';

const useStyles = makeStyles({
  titleReview: {
    fontSize: '42px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '14px',
    marginTop: '25px',
    color: '#FE7D1F',
    '@media (max-width: 600px)': {
      fontSize: '60px',
    },
  },
  textReview: {
    fontSize: '22px',
    textAlign: 'center',
    marginBottom: '33px',
    color: '#FE7D1F',
    '@media (max-width: 600px)': {
      fontSize: '26px',
    },
  },
  aboutTitle: {
    color: '#445E77',
    fontSize: '40px',
    textAlign: 'center',
  },
  aboutText: {
    marginBottom: 16,
  },
  bigDiv: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: '95px',
  },
  title1: {
    fontSize: '42px',
    fontWeight: '500',
    lineHeight: '49px',
    paddingBottom: '26px',
  },
  title2: {
    fontSize: '17px',
    lineHeight: '35px',
    fontFamily: 'Roboto',
    paddingBottom: '26px',
  },
  AbouLigaRightImg: {
    '@media (max-width: 990px)': {
      display: 'none',
    },
  },
});

const PresentHistory = () => {
  const classes = useStyles();
  const lg = useMediaQuery('(max-width:1440px)');
  return (
    <>
      <Box>
        <Container maxWidth={lg ? 'lg' : 'xl'}>
          <Box className={classes.bigDiv}>
            <Box>
              <Typography className={classes.title1}>О СЕРВИСЕ ЛИГА УСЛУГ</Typography>
              <Typography className={classes.title2}>
                Лига Услуг - это современный и удобный сервис, который поможет вам найти нужного
                исполнителя для решения любой задачи. Новая платформа объединяет проверенных
                мастеров по ремонтным, строительным, IT технологиям, хозяйственным работам,
                грузоперевозкам, юридическим услугам, красоте и здоровью, уборке помещений, услугам
                для бизнеса, выгулу животных и многим другим направлениям вашего города.Каждый
                исполнитель проходит необходимую верификацию, что гарантирует высокое качество
                предоставляемых услуг. Вы можете быть уверены, что найдете опытного специалиста,
                который выполнит ваше задание за минимальные сроки и по приемлемой цене.Просто
                оставьте задание на сайте Лига услуг и ожидайте отклика от специалистов своего дела.
                Быстрый и удобный поиск профессиональных исполнителей, которые готовы помочь вам в
                любой ситуации.Присоединяйтесь к Лиге Услуг и найдите лучшего исполнителя для
                решения вашей задачи уже сегодня!
              </Typography>
              <Grid container direction="row"  alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.titleReview} color={'#445E77'}>
                    300+
                  </Typography>
                  <Typography className={classes.textReview} color={'#000000'}>
                    Активных Заказов
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.titleReview} color={'#445E77'}>
                    1800+
                  </Typography>
                  <Typography className={classes.textReview} color={'#000000'}>
                    Опытных Специалистов
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.titleReview} color={'#445E77'}>
                    9500+
                  </Typography>
                  <Typography className={classes.textReview} color={'#000000'}>
                    Выполненных Заказов
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <img src={AboutLigaImg} alt="Abot_LIga" className={classes.AbouLigaRightImg} />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PresentHistory;
