import * as React from "react";


const VkSvg = ({size= 27,color="#333333"}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.64 0H9.36C1.8 0 0 1.8 0 9.36V17.64C0 25.2 1.8 27 9.36 27H17.64C25.2 27 27 25.2 27 17.64V9.36C27 1.8 25.2 0 17.64 0ZM21.5157 8.57812C22.111 8.57812 22.2361 8.88465 22.111 9.30181C21.8607 10.449 19.4621 13.8279 19.4621 13.8279C19.2535 14.1616 19.1701 14.3285 19.4621 14.7038C19.5649 14.8478 19.7844 15.0628 20.0354 15.3087C20.2936 15.5616 20.5852 15.8472 20.8179 16.1222C21.6635 17.0736 22.3009 17.8762 22.4784 18.4293C22.6407 18.9844 22.3594 19.2656 21.7969 19.2656H19.8282C19.3008 19.2656 19.0353 18.9702 18.4615 18.332C18.2183 18.0614 17.9197 17.7293 17.5224 17.3319C16.3543 16.2056 15.8537 16.0596 15.5618 16.0596C15.1655 16.0596 15.0469 16.1719 15.0469 16.7344V18.4999C15.0469 18.9844 14.8921 19.2656 13.6407 19.2656C11.5549 19.2656 9.26277 17.9993 7.63591 15.6633C5.1956 12.2427 4.52815 9.65637 4.52815 9.13492C4.52815 8.84292 4.64066 8.57812 5.20316 8.57812H7.17191C7.67507 8.57812 7.86433 8.79834 8.05303 9.34351C9.01591 12.1484 10.6394 14.5996 11.3068 14.5996C11.5571 14.5996 11.6719 14.4844 11.6719 13.8487V10.9495C11.6267 10.1243 11.3384 9.76737 11.1249 9.50302C10.9931 9.33978 10.8897 9.21182 10.8897 9.03065C10.8897 8.81046 11.0778 8.57812 11.3907 8.57812H14.4844C14.9016 8.57812 15.0469 8.80122 15.0469 9.30181V13.2022C15.0469 13.6193 15.228 13.7653 15.3532 13.7653C15.6035 13.7653 15.812 13.6193 16.2709 13.1604C17.6892 11.5753 18.6904 9.13492 18.6904 9.13492C18.8155 8.84292 19.0463 8.57812 19.5469 8.57812H21.5157Z"
                  fill={color}/>
        </svg>

      )

  };
  export default VkSvg;
