export const NOTIFICATION_STATUSES = {
   // "App\\Notifications\\NotifyAsTaskExecutor": "Вас выбрали",
   // "App\\Notifications\\RatingForCompletedTask": "Работа выполнена",
   'App\\Notifications\\NotifyExecutorForDeadline': 'Срок подходит к концу',
   'App\\Notifications\\NotifyEmployerExecutorCompletedTask': 'Работа завершена',
   'App\\Notifications\\NotifiyEmployer': 'На ваше задание появился новый отклик',
   'App\\Notifications\\RatingForCompletedTask': 'Работа завершена',
   'App\\Notifications\\RejectTaskExecutorNotification': 'Вас отклонили',
   'App\\Notifications\\SupportFeedbackNotification': 'Сообщение от модератора',
   'App\\Notifications\\NotifyEmployerForGettingRating':
      'Вас оценил заказчик. Проверьте ваш рейтинг',
   'App\\Notifications\\NotifyExecutorForGettingRating':
      'Вас оценил пользователь. Проверьте ваш рейтинг',
   'App\\Notifications\\NotifyAsTaskExecutor': 'Вы были выбраны',
   'App\\Notifications\\NotifyExecutorEmployerCompletedTask': 'Заказчик завершил заказ',
   'App\\Notifications\\NotifyExecutorForNewJobEveryTime': 'Есть новая задача',
   'App\\Notifications\\NotifyEmployerExecutorRejectedSpecialTask':
      'Ваш персональный заказ был отменён',
   'App\\Notifications\\NotifyExecutorForSpecialTask': 'Вам отправлен персональный заказ',
   'App\\Notifications\\NotifyEmployerForDeletingTask':
      'Ваша вакансия удалена. Можете разместить повторно.',
   'App\\Notifications\\NotifyExecutorForNewJob': '',
   // "App\\Notifications\\NotifyExecutorForSpecialTask"

   'App\\Notifications\\NotifyEmployerDeleteTaskFromThreeDay': 'Ваш заказ завтро будет удален ',
   // 'App\\Notifications\\NotifyEmployerDeleteTaskFromTwoDay': 'Ваш заказ будет удален, через 2 дня',
   'App\\Notifications\\NotifyEmployerDeleteTaskFromTwoDay': 'На ваше задаче нет откликов, ',
   "App\\Notifications\\ReturnedMoneyExecutorTwoDay": 'Возврат денег Исполнителю'
};

export const CHECK_EXECUTOR_ICON = [
   'App\\Notifications\\NotifiyEmployer',
   'App\\Notifications\\NotifyEmployerExecutorCompletedTask',
   'App\\Notifications\\NotifiyEmployer',
   'App\\Notifications\\NotifyEmployerForGettingRating',
   'App\\Notifications\\NotifyExecutorEmployerCompletedTask',
   'App\\Notifications\\NotifyEmployerExecutorRejectedSpecialTask',
   'App\\Notifications\\NotifyEmployerForDeletingTask',

   'App\\Notifications\\NotifyEmployerDeleteTaskFromTwoDay',
   'App\\Notifications\\NotifyEmployerDeleteTaskFromThreeDay',
];

export const EMPLOYER_RATING = ['App\\Notifications\\NotifyEmployerForGettingRating'];
export const EXECUTOR_RATING = ['App\\Notifications\\NotifyExecutorForGettingRating'];

export const EXECUTOR_LINK = [
   'App\\Notifications\\NotifyExecutorForDeadline',
   'App\\Notifications\\NotifyAsTaskExecutor',
   'App\\Notifications\\NotifyExecutorForNewJobEveryTime',
   'App\\Notifications\\NotifyExecutorForSpecialTask',
   'App\\Notifications\\NotifyExecutorForGettingRating',
   "App\\Notifications\\NotifyEmployerExecutorCompletedTask",
   "App\\Notifications\\ReturnedMoneyExecutorTwoDay"
];

export const EMPLOYER_LINK = [
   'App\\Notifications\\NotifiyEmployer',
   'App\\Notifications\\NotifyEmployerForGettingRating',
   'App\\Notifications\\NotifyExecutorEmployerCompletedTask',
   'App\\Notifications\\NotifyEmployerForGettingRating',
   'App\\Notifications\\NotifyEmployerExecutorRejectedSpecialTask',
  
   'App\\Notifications\\NotifyEmployerDeleteTaskFromTwoDay',   // naxazgushacum 2 rd or
   'App\\Notifications\\NotifyEmployerDeleteTaskFromThreeDay', // naxazgushacum 3 or@
   'App\\Notifications\\NotifyEmployerForDeletingTask',        // jnjvel e
];
